import { Form, Button, Input, Row, Col, Switch, Select } from "antd";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import MediaSelect from "./mediaSelect.field";
import './editor.field.scss';
import JoditEditor from "jodit-react";

function Editor(props) {
    const { t } = useTranslation();
    // Form List
    return <div>
        <h1>{props.label ? props.label : t('editor.title')}</h1>
        <Form.List
            name={props.name}
            rules={props.rules ? props.rules : []}
        >
            {(fields, { add, remove, move }) => {
                return <>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: 10 }}>
                        <Button type="dashed" onClick={() => add(null, 0)} block icon={<PlusOutlined />}>
                            {t('editor.addBefore')}
                        </Button>
                    </Form.Item>
                    {fields.map(({ key, name, ...restField }, fieldPosition) => {
                        return <div className="editor-module-component" key={key} style={{ display: 'flex', width: '100%' }} align="baseline">
                            <div style={{ width: 'calc(100% - 100px)' }}>
                                <Row gutter={10}>
                                    <Col span={6}>
                                        <Form.Item noStyle>
                                            <Form.Item
                                                name={[name, 'type']}
                                                wrapperCol={{ span: 24 }}
                                                style={{ marginBottom: 10 }}>
                                                <Select placeholder={t('editor.label.type')}>
                                                    <Select.Option value="paragraph">{t('editor.paragraph')}</Select.Option>
                                                    <Select.Option value="image">{t('editor.image')}</Select.Option>
                                                    <Select.Option value="heading">{t('editor.heading')}</Select.Option>
                                                    <Select.Option value="quote">{t('editor.quote')}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Form.Item>
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            noStyle
                                            dependencies={[props.name.concat(name, 'type')]}
                                        >
                                            {() => {
                                                let type = props.form.getFieldValue(props.name.concat(name, 'type'));
                                                switch (type) {
                                                    case 'paragraph':
                                                        return <JoditEditor
                                                            value={props.form.getFieldValue(props.name.concat(name, 'content'))}
                                                            onBlur={(value) => props.form.setFieldValue(props.name.concat(name, 'content'), value)}
                                                            placeholder={t('editor.type.paragraph')}
                                                            config={{
                                                                minHeight: '400px',
                                                                toolbarAdaptive: false,
                                                            }}
                                                        />;
                                                    case 'quote':
                                                    case 'heading':
                                                        return <>
                                                            {type === 'heading' && <Form.Item
                                                                name={[name, 'size']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('editor.field.required')
                                                                    },
                                                                ]}
                                                                initialValue="h2"
                                                            >
                                                                <Select placeholder={t('editor.label.headingSize')}>
                                                                    <Select.Option value="h1">H1</Select.Option>
                                                                    <Select.Option value="h2">H2</Select.Option>
                                                                    <Select.Option value="h3">H3</Select.Option>
                                                                    <Select.Option value="h4">H4</Select.Option>
                                                                    <Select.Option value="h5">H5</Select.Option>
                                                                    <Select.Option value="h6">H6</Select.Option>
                                                                </Select>
                                                            </Form.Item>}
                                                            <div>{t('editor.label.' + type)}</div>
                                                            <Form.Item
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('editor.field.required')
                                                                    },
                                                                ]}
                                                                name={[name, 'content']}
                                                                wrapperCol={{ span: 24 }}>
                                                                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('editor.type.' + type)} />
                                                            </Form.Item>
                                                        </>
                                                    case 'image':
                                                        return <>
                                                            <Row gutter={10}>
                                                                <Col span={12}>
                                                                    <div>{t('editor.label.imageContainerSize')}</div>
                                                                    <Form.Item
                                                                        initialValue={'default'}
                                                                        name={[name, 'containerSize']}
                                                                        wrapperCol={{ span: 24 }}
                                                                    >
                                                                        <Select placeholder={t('editor.label.imageContainerSize')}>
                                                                            <Select.Option value="default">Default</Select.Option>
                                                                            <Select.Option value="full">Full Width</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <div>{t('editor.label.imageSize')}</div>
                                                                    <Form.Item
                                                                        initialValue={'full'}
                                                                        name={[name, 'imageSize']}
                                                                        wrapperCol={{ span: 24 }}
                                                                    >
                                                                        <Select placeholder={t('editor.label.imageSize')}>
                                                                            <Select.Option value="full">Same as container</Select.Option>
                                                                            <Select.Option value="narrow">Narrow</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <div>{t('editor.label.image')}</div>
                                                            <MediaSelect
                                                                fieldName={props.name.concat(name, 'content')}
                                                                showMediaDrawer={props.showMediaDrawer}
                                                                form={props.form} wrapper={{
                                                                    wrapperCol: 24
                                                                }} inner={{
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: t('editor.field.required')
                                                                        },
                                                                    ],
                                                                    name: [name, 'content'],
                                                                }} />
                                                            <div>{t('editor.label.imageCaption')}</div>
                                                            <Form.Item
                                                                name={[name, 'caption']}
                                                                wrapperCol={{ span: 24 }}>
                                                                <Input placeholder={t('editor.type.imageCaption')} />
                                                            </Form.Item>
                                                        </>
                                                    default:
                                                        return null;
                                                }
                                            }}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ width: 100 }}>
                                {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8, fontSize: 16 }} />}
                                <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8, fontSize: 16 }} />
                            </div>
                        </div >
                    })}
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            {t('editor.addAfter')}
                        </Button>
                    </Form.Item>
                </>
            }}
        </Form.List>
    </div>
}

export default Editor;